import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    ApexCharts.exec("radarSignature", "dataURI").then(({ imgURI }) => {
      //console.log(imgURI);
			//var div = document.getElementById('_test_box');
			//div.innerHTML += imgURI;
      Rails.ajax({
        url: this.data.get("url"),
        type: 'POST',
        data: `img_png=${imgURI}`
      });
    });
  }
}
